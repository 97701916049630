import React from 'react'
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube'
import { ReactComponent as EmTeamSVG } from '../images/em-team.svg';
import '../css/App.css';

const Nordstrom = () => {
    
  return (
    <div>
      <div className="row">
        <div className="column three">
          <div className="caseStudyLogo"><EmTeamSVG/></div>
            <div className="caseStudyTitle">
              SEATTLE MUSIC PROJECT<br/>
              INTERACTIVE WINDOW<br/>
              2012 &amp; 2014
            </div>
            <div className="caseStudyVideo">
              <ReactPlayer className='react-player' url='https://youtu.be/hJyTt9sbtuQ' width='100%' height='100%'/>
            </div>
            <div className="caseStudyVideo">
              <ReactPlayer className='react-player' url='https://youtu.be/jV5n29Iv5qM' width='100%' height='100%'/>
            </div>
            <div className="caseStudyText">
            <strong>Summary</strong><br/>
            Nordstrom and The Experience Music Project (now known as the Museum of Pop Culture or MoPOP) teamed up with music photographer Lance Mercer, to create an exhibit showcasing the history of Seattle's music scene.<br/><br/>
            <strong>Background</strong><br/>
            As part of the exhibit my team was asked to design and develop a method for customers to browse a timeline of photography, artwork and music from Seattle area bands while on the sidewalk and looking into a store window.<br/><br/>
            <strong>Solution</strong><br/>
            We met with the museum curators at MoPOP to learn about their collection of memorabilia and collect images for design inspiration and reference. We designed the UI elements of the touchscreen to mimic the controls of an analog radio. The UI was rear-projected onto 2-layer capacitive-touch enabled projection film from 3M and TouchPlanet, which could read touch input through display window glass. This film came with proprietary middleware that provided touch events to our software. Our software retrieved pre-selected audio tracks that would play on the store's overhead speakers, as customers navigated the UI and switched between bands on the timeline. Some of the content in the UI was tied to specific physical memorabilia in the window. We used a lighting communication protocol called DMX512 and a specialized hardware controller to enable our UI software to communicate with the lighting hardware.<br/><br/>
            <strong>Impact</strong><br/>
            The project ran for about a month during the summer of 2012 in downtown Seattle. We registered thousands of interactions and even got coverage on local media. This exhibit was so successful that Nordstrom followed it up in 2014, partnering with Sub Pop Records and featuring artists from their label. During the run of the 2014 version, <a href="https://www.seattletimes.com/seattle-news/sub-pop-founders-find-a-step-on-nordstrom-walk-of-fame">Jonathan Poneman and Bruce Pavitt were inducted into the Nordstrom Walk of Fame</a>.<br/><br/>
            <strong>Press</strong><br/>
            • <a href="https://www.seattletimes.com/seattle-news/sub-pop-founders-find-a-step-on-nordstrom-walk-of-fame">Seattle Times</a>
          </div>
          <div className="caseStudyTitle">
              WRITING WITH LIGHT<br/>
              INTERACTIVE WINDOW<br/>
              2011
            </div>
            <div className="caseStudyVideo">
              <ReactPlayer className='react-player' url='https://youtu.be/p85b87FKecU' width='100%' height='100%'/>
            </div>
            <div className="caseStudyText">
            <strong>Summary</strong><br/>
            Nordstrom partnered with fashion photographer Glen Luchford and artist Ruben Toledo to create an interactive window experience inspired by their March Designer Catalog.<br/><br/>
            <strong>Background</strong><br/>
            My team was asked to design and develop a way for customers to paint with light by touching the glass of a sidewalk-facing display window with their hands.
            <br/><br/>
            <strong>Solution</strong><br/>
            We designed the UI elements based on the design style of artist Ruben Toledo. Our custom software consumed point-cloud data from a first generation Microsoft Kinect 3D camera, identifying and then translating the position of a customer's hands on the window glass into digital paint brushes that were projected on the back wall of the window. As customers moved their hands around the paint brushes would leave a trail of light, allowing messages to be written which would persist for about a minute before fading away. We also developed an auto-play mode that automatically wrote messages if the software was idle for a couple minutes.
            <br/><br/>
            <strong>Impact</strong><br/>
            Although more common now, this was the first time 3D camera technology was utilized in this way in a retail store window and we received <a href="https://www.geekwire.com/2011/write-nordstrom-window-microsofts-kinect/">coverage from local media like Geekwire</a> and the attention of some Seattle area tech companies that had been researching similar methods of interaction.<br/><br/>
            <strong>Press</strong><br/>
            • <a href="https://www.geekwire.com/2011/write-nordstrom-window-microsofts-kinect/">Geekwire</a>
          </div>
          <Link to="/">
            <div className="columnButton third">
              BACK
            </div>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default Nordstrom