import React from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as FdtTeamSVG } from '../images/fdt-team.svg';
import { ReactComponent as RFIDTraySVG } from '../images/rfid-tray.svg';
import '../css/App.css';

const Fulfillment = () => {
    
  return (
    <div>
      <div className="row">
        <div className="column two">
          <div className="caseStudyLogo"><FdtTeamSVG/></div>
          <div className="caseStudyTitle">
              RFID INVENTORY CART<br/>
              HARDWARE PROTOTYPE<br/>
              2016
            </div>
            <div className="caseStudyImage">
              <RFIDTraySVG/>
            </div>
            <div className="caseStudyText">
            <br/><br/>
            <strong>Summary</strong><br/>
            My team researched, designed, built and tested a touch-screen operable inventory counting device that enabled fulfillment center associates to quickly and accurately count RFID tagged merchandise at Amazon's fulfillment centers.
            <br/><br/>
            <strong>Background</strong><br/>
            Amazon regularly audits the inventory of merchandise across their global network of fulfillment centers. In 2016 the auditing process was largely manual, and involved associates hand-scanning each item to match it against the inventory database. This was a labor intensive process that we estimated cost Amazon $26MM annually. Amazon was seeking to reduce the overall operational costs associated with inventory audits. Around that time, Amazon was also heavily invested in exploring RFID as a platform for automating a variety of processes, including inventory audits. My team was asked to investigate a method for automating inventory counts of RFID tagged merchandise. Our solution needed to be operable on a mobile cart and account for items that were stacked or touching each other. The user experience needed to be simple, intuitive and require little to no training. The existing data provided to my team was related to the performance of the existing manual processes.
            <br/><br/>
            <strong>Solution</strong><br/>
            We observed multiple inventory audits at various fulfillment centers to document existing challenges and interview the associates that led these audits. Based on our insights and the requirements initially provided to us, we designed and built a custom tray that could be set into the top section of the mobile carts used in Amazon fulfillment centers. The tray included a series of RFID cable antennas that pulsed multiple times in succession to ensure all RFID tags were counted. We chose a mounted touchscreen to act as the primary method for operation and to provide a visual readout of RFID tags counted during each scan. We designed the user interface with simple high-contrast graphics to maintain readability in variable lighting conditions. We reduced the operational steps to a minimum and provided clear and continuous system status messaging. The tray had a standard 3 prong plug that could be used with any mobile power supply.
            <br/><br/>
            <strong>Impact</strong><br/>
            We tested multiple design variants of this tray at fulfillment centers in the United States and Japan over a 2 year period. Our designs received multiple patents which influenced the design of future inventory related devices used in Amazon's fulfillment and grocery businesses.
            <br/><br/>
            <strong>Awarded Patents</strong><br/>
            • <a href="https://patentimages.storage.googleapis.com/f2/e6/3e/412cf9b9093e9d/US10402600.pdf">Patent US10402600B1</a>
            <br/>
            • <a href="https://patentimages.storage.googleapis.com/be/0f/34/28483803688ac4/US10438030.pdf">Patent US10438030B2</a>
            <br/>
            • <a href="https://patentimages.storage.googleapis.com/4f/1e/4e/19c997ef2ef7b4/US10121034.pdf">Patent US10121034B1</a>
          </div>
          <Link to="/">
            <div className="columnButton second">
              BACK
            </div>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default Fulfillment