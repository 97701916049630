import React from 'react'
import { Link } from 'react-router-dom';
import '../css/App.css';
const Header = () => {
  return (
    <Link to="/">
      <div className="header">
        <div className="title">
          ALLAN BATHURST
        </div>
        <div className="subtitle">
          DESIGN LEADERSHIP
        </div>
      </div>
    </Link>
  )
}

export default Header;