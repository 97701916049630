import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import Home from './Home'
import Nordstrom from './Nordstrom'
import Fulfillment from './Fulfillment'
import Advertising from './Advertising'

const Main = () => (
  <div id="content">
    <main>
     <ScrollToTop />
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/nordstrom' component={Nordstrom}/>
        <Route exact path='/fulfillment' component={Fulfillment}/>
        <Route exact path='/advertising' component={Advertising}/>
      </Switch>
    </main>
  </div>
)

export default Main