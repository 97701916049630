import React from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as CaptTeamSVG } from '../images/capt-team.svg';
import { ReactComponent as FdtTeamSVG } from '../images/fdt-team.svg';
import { ReactComponent as EmTeamSVG } from '../images/em-team.svg';
import '../css/App.css';

const Home = () => {
  return (
    <div>
      <div className="row">
          <div className="column one">
            <CaptTeamSVG/>
            <div className="columnTitle">
              AMAZON ADS<br/>
              2018 - 2023
            </div>
            <div className="columnText">
              I established, built and led CAPT UX, a multi-disciplinary team of designers, researchers, product managers and design technologists that envisioned, designed and delivered new products for Amazon Ads. We followed a data-driven methodology that uncovered and addressed issues early in the product development lifecycle. This enabled us to mitigate the impact of one-way door decisions and costly post-launch fixes. I partnered regularly with senior leadership in product management and engineering for operational planning, organizational strategy and tactical delivery while leading this team.
            </div>
            <Link to="/advertising">
            <div className="columnButton first">
              CASE STUDIES
            </div>
            </Link>
          </div>
        <div className="column two">
          <FdtTeamSVG/>
          <div className="columnTitle">
              AMAZON OPERATIONS<br/>
              2015 - 2018
          </div>
          <div className="columnText">
              I established, built and led Fulfillment Design Technology, a team of design technologists that solved critical user experience problems for Amazon Operations. We researched, designed, built, tested and deployed hardware and software prototypes that reduced the complexity and improved the safety of fulfillment processes. I partnered regularly with leadership in product management, design, user research and engineering for operational planning, organizational strategy and tactical delivery while leading this team.
          </div>
          <Link to="/fulfillment">
            <div className="columnButton second">
              CASE STUDIES
            </div>
            </Link>
        </div>
        <div className="column three">
          <EmTeamSVG/>
            <div className="columnTitle">
              NORDSTROM RETAIL<br/>
              2010 - 2015
            </div>
            <div className="columnText">
              I established, built and led Emerging Media, a team of creative technologists that delivered interactive branded experiences for Nordstrom retail. We researched, designed, built, tested, deployed and maintained hardware and software that was purpose-built for these retail environments. We drove brand awareness while providing a memorable customer experience. I partnered regularly with leadership in product management, user research and merchandising for operational planning and tactical delivery while leading this team.
          </div>
          <Link to="/nordstrom">
            <div className="columnButton third">
              CASE STUDIES
            </div>
          </Link>
        </div>
      </div>
  </div>
  )
}

export default Home