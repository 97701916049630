import React from 'react';
import '../css/App.css';
import Header from './Header'
import Main from './Main'
const App = () => (
  <div>
    <Header/>
    <Main/>
  </div>
)

export default App;
