import React from 'react';
import { render } from 'react-dom'
import { HashRouter } from 'react-router-dom'
import './css/index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

render((
  <HashRouter>
    <App />
  </HashRouter>
  ), document.getElementById('root')
);
serviceWorker.unregister();