import React from 'react'
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube'
import { ReactComponent as CaptTeamSVG } from '../images/capt-team.svg';
import '../css/App.css';

const Advertising = () => {
    
  return (
    <div>
      <div className="row">
        <div className="column one">
          <div className="caseStudyLogo"><CaptTeamSVG/></div>
          <div className="caseStudyTitle">
              INSITU<br/>
              SOFTWARE APPLICATION<br/>
              2020
            </div>
            <div className="caseStudyVideo">
              <ReactPlayer className='react-player' url='https://youtu.be/kW_yTI2PzG8' width='100%' height='100%'/>
            </div>
            <div className="caseStudyText">
            <strong>Summary</strong><br/>
            INSITU expedites the creative development and approval process for Out of Home Ads campaigns. Through INSITU, flat artwork transforms into interactive 3D renderings in seconds and can be routed for internal approvals in minutes.
            <br/><br/>
            <strong>Background</strong><br/>
            My team observed that creatives in our organization were spending a significant amount of time manually preparing 3D assets to review packaging designs with internal stakeholders and external clients. These designs were first created in 2D using existing graphics software and then adapted to mimic the appearance of a 3D form later. To ensure we had a complete understanding of the existing processes, we interviewed art directors, project managers and account executives that supported the Out of Home Ads program. A key insight we uncovered was that the review process was primarily hand-managed at that time, with communication spread across multiple platforms and tools, leading to delays in feedback and approval gaps. Using this and other key insights, we envisioned a streamlined process for generating, reviewing, approving and sharing all 3D assets for package designs in the Out of Home Ads program.
            <br/><br/>
            <strong>Solution</strong><br/>
            My team designed and built a browser-based application that allowed creatives to drag and drop 2D mock-ups of their packaging designs onto a 3D form. That 3D form could be a box, bag or padded mailing envelope. The review and approval processes for these designs are now embedded in the workflow of INSITU. Additionally, creatives can export a variety of 3D assets, including video clips that simulate the experience of opening a box, bag or envelope with their custom design printed on it. In late 2019 we ran internal usability testing and released v1 in Q1 2020 to immediate accolades from individual contributors and leadership. Over the next 3 years We evolved INSITU through multiple versions. INSITU's feature-set expanded to include more customization options for exported 3D assets, including the ability to simulate environmental conditions like lighting and a patent-pending UI control to simulate the impact of print-offset variance.
            <br/><br/>
            <strong>Impact</strong><br/>
            Within its first year, INSITU saved over 1,500 hours of manual effort across 50+ campaigns. INSITU was our first foray into research, design and development of production-grade internal tools. Over the next few years we grew this effort into a suite of Ad Workflow Tools that saved our organization $6MM annually by 2023. We even submitted a patent application for the unique aspects of the INSITU UX, which is currently in review with the US patent office. In late 2022, INSITU was featured in articles in <a href="https://adage.com/article/marketing-news-strategy/amazon-ads-box-advertising-4x-more-effective-traditional-media/2440636"><strong>Ad Age</strong></a> and the <a href="https://advertising.amazon.com/en-us/blog/paramount-box-advertising"><strong>Amazon Ads Blog</strong></a> that outlined the impact of the tool on Amazon's on-box advertising business. Those articles generated interest with external businesses who inquired about getting access to INSITU for use on their own design projects. In early 2023 we began scoping the investment required to externalize INSITU for 3rd parties.
            <br/><br/>
            <strong>Press</strong><br/>
            • <a href="https://adage.com/article/marketing-news-strategy/amazon-ads-box-advertising-4x-more-effective-traditional-media/2440636">Ad Age</a><br/>
            • <a href="https://advertising.amazon.com/en-us/blog/paramount-box-advertising">Amazon Ads Blog</a>
          </div>
          <Link to="/">
            <div className="columnButton first">
              BACK
            </div>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default Advertising